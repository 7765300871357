import React, { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext();

function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(
    localStorage.getItem('ib-theme') || 'dark',
  );
  const changeTheme = useCallback(
    (themeType) => {
      setTheme(themeType);
      localStorage.setItem('ib-theme', themeType);
      document.querySelector('body').className = `${themeType}-theme`;
    },
    [setTheme],
  );

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeContextProvider.defaultProps = {};

export default ThemeContextProvider;
