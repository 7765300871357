import React from 'react';
import PropTypes from 'prop-types';

function LogoIcon({ className, type }) {
  const randomNumber = Math.random();
  switch (type) {
    case 'light':
      return (
        <svg
          className={className}
          viewBox="0 0 147.74 24.38"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id={`linear-gradient-light-${randomNumber}`}
              x1="133.41"
              x2="147.61"
              y1="8.24"
              y2="0.37"
            >
              <stop offset="0" stopColor="#ee7219" />
              <stop offset="1" stopColor="#f39313" />
            </linearGradient>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id={`linear-gradient-light-2-${randomNumber}`}
              x1="20.13"
              x2="3.35"
              y1="0.11"
              y2="24.45"
            >
              <stop offset="0" stopColor="#f39313" />
              <stop offset="1" stopColor="#ee7219" />
            </linearGradient>
          </defs>
          <g dataName="Layer 2" id="Layer_2">
            <g id="Loga">
              <polygon
                fill={`url(#linear-gradient-light-${randomNumber})`}
                points="147.74 0.14 145.11 0.14 143.26 2.73 142.35 0.14 140.64 0.14 140.64 0.14 134.94 0.14 133.36 8.28 135.55 8.28 136.08 5.5 138.52 5.5 139.77 3.96 136.37 3.96 136.8 1.68 140.68 1.68 141.72 3.96 138.21 8.28 140.84 8.28 142.99 5.36 144.06 8.28 146.42 8.28 144.55 4.02 147.74 0.14"
              />
              <polygon
                fill={`url(#linear-gradient-light-2-${randomNumber})`}
                points="0 24.38 23.61 24.38 23.61 13.38 12.9 13.38 12.9 18.12 10.71 18.12 10.71 6.24 12.9 6.24 12.9 11 23.61 11 23.61 0 0 0 0 24.38"
              />
              <path
                d="M54.79,8.58c-.24.94-.45,1.89-.61,2.85h0c-.19-1-.45-1.95-.7-2.79l-.88-3.15H49.82l2.9,8.13v5.05h2.56V13.52l2.95-8H55.57Z"
                fill="#003c70"
              />
              <path
                d="M107.58,14.05c0,2-.53,2.52-1.27,2.52s-1.25-.58-1.25-2.52V5.49H102.5v8.13c0,3.29,1.07,5.2,3.83,5.2,2.52,0,3.81-1.84,3.81-5.22V5.49h-2.56Z"
                fill="#003c70"
              />
              <path
                d="M124.76,11c-1.4-.86-1.8-1.27-1.8-2.07s.53-1.3,1.63-1.3a3.71,3.71,0,0,1,1.66.4l.41-2.21a4.67,4.67,0,0,0-2.07-.47c-2.74,0-4.15,1.76-4.15,3.87a4.47,4.47,0,0,0,2.49,3.68c1.11.72,1.67,1.16,1.67,2.12,0,.76-.72,1.38-1.92,1.38a5.64,5.64,0,0,1-2-.37l-.37,2.2a5.75,5.75,0,0,0,2.52.53c2.56,0,4.34-1.58,4.34-4A4.27,4.27,0,0,0,124.76,11Z"
                fill="#003c70"
              />
              <path
                d="M40.18,7.63h0a13,13,0,0,1,1.48,0L42,5.42s-.87,0-1.78,0h0c-2.56,0-5.32,1.94-5.32,6.9,0,3.75,1.61,6.51,5,6.51h0a14.64,14.64,0,0,0,2-.12l-.24-2.17-1.28,0c-1.57,0-2.76-1.23-2.76-4.37S38.8,7.72,40.18,7.63Z"
                fill="#003c70"
              />
              <path
                d="M74.36,9.81c0-3.21-1.71-4.38-4.51-4.38a24.87,24.87,0,0,0-2.71.14v13.1h2.41V14.29a7.77,7.77,0,0,0,1.09-.08l1.61,4.46h2.52l-2-5.33A4.15,4.15,0,0,0,74.36,9.81Zm-4.81,2V8.07C71,8,71.84,8.48,71.84,9.89S70.88,11.82,69.55,11.81Z"
                fill="#003c70"
              />
              <path
                d="M92.08,9.81c0-3.21-1.71-4.38-4.51-4.38a24.87,24.87,0,0,0-2.71.14v13.1h2.41V14.29a7.77,7.77,0,0,0,1.09-.08L90,18.67h2.52l-2-5.33A4.15,4.15,0,0,0,92.08,9.81Zm-4.81,2V8.07c1.45-.12,2.29.41,2.29,1.82S88.59,11.82,87.27,11.81Z"
                fill="#003c70"
              />
            </g>
          </g>
        </svg>
      );
    case 'dark':
    default:
      return (
        <svg
          className={className}
          viewBox="0 0 147.74 24.38"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id={`linear-gradient-${randomNumber}`}
              x1="133.41"
              x2="147.61"
              y1="8.24"
              y2="0.37"
            >
              <stop offset="0" stopColor="#ee7219" />
              <stop offset="1" stopColor="#f39313" />
            </linearGradient>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id={`linear-gradient-2-${randomNumber}`}
              x1="20.13"
              x2="3.35"
              y1="0.11"
              y2="24.45"
            >
              <stop offset="0" stopColor="#f39313" />
              <stop offset="1" stopColor="#ee7219" />
            </linearGradient>
          </defs>
          <g dataName="Layer 2" id="Layer_2">
            <g id="Loga">
              <polygon
                fill={`url(#linear-gradient-${randomNumber})`}
                points="147.74 0.14 145.11 0.14 143.26 2.73 142.35 0.14 140.64 0.14 140.64 0.14 134.94 0.14 133.36 8.28 135.55 8.28 136.08 5.5 138.52 5.5 139.77 3.96 136.37 3.96 136.8 1.68 140.68 1.68 141.72 3.96 138.21 8.28 140.84 8.28 142.99 5.36 144.06 8.28 146.42 8.28 144.55 4.02 147.74 0.14"
              />
              <polygon
                fill={`url(#linear-gradient-2-${randomNumber})`}
                points="0 24.38 23.61 24.38 23.61 13.38 12.9 13.38 12.9 18.12 10.71 18.12 10.71 6.24 12.9 6.24 12.9 11 23.61 11 23.61 0 0 0 0 24.38"
              />
              <path
                d="M54.79,8.58c-.24.94-.45,1.89-.61,2.85h0c-.19-1-.45-1.95-.7-2.79l-.88-3.15H49.82l2.9,8.13v5.05h2.56V13.52l2.95-8H55.57Z"
                fill="#fff"
              />
              <path
                d="M107.58,14.05c0,2-.53,2.52-1.27,2.52s-1.25-.58-1.25-2.52V5.49H102.5v8.13c0,3.29,1.07,5.2,3.83,5.2,2.52,0,3.81-1.84,3.81-5.22V5.49h-2.56Z"
                fill="#fff"
              />
              <path
                d="M124.76,11c-1.4-.86-1.8-1.27-1.8-2.07s.53-1.3,1.63-1.3a3.71,3.71,0,0,1,1.66.4l.41-2.21a4.67,4.67,0,0,0-2.07-.47c-2.74,0-4.15,1.76-4.15,3.87a4.47,4.47,0,0,0,2.49,3.68c1.11.72,1.67,1.16,1.67,2.12,0,.76-.72,1.38-1.92,1.38a5.64,5.64,0,0,1-2-.37l-.37,2.2a5.75,5.75,0,0,0,2.52.53c2.56,0,4.34-1.58,4.34-4A4.27,4.27,0,0,0,124.76,11Z"
                fill="#fff"
              />
              <path
                d="M40.18,7.63h0a13,13,0,0,1,1.48,0L42,5.42s-.87,0-1.78,0h0c-2.56,0-5.32,1.94-5.32,6.9,0,3.75,1.61,6.51,5,6.51h0a14.64,14.64,0,0,0,2-.12l-.24-2.17-1.28,0c-1.57,0-2.76-1.23-2.76-4.37S38.8,7.72,40.18,7.63Z"
                fill="#fff"
              />
              <path
                d="M74.36,9.81c0-3.21-1.71-4.38-4.51-4.38a24.87,24.87,0,0,0-2.71.14v13.1h2.41V14.29a7.77,7.77,0,0,0,1.09-.08l1.61,4.46h2.52l-2-5.33A4.15,4.15,0,0,0,74.36,9.81Zm-4.81,2V8.07C71,8,71.84,8.48,71.84,9.89S70.88,11.82,69.55,11.81Z"
                fill="#fff"
              />
              <path
                d="M92.08,9.81c0-3.21-1.71-4.38-4.51-4.38a24.87,24.87,0,0,0-2.71.14v13.1h2.41V14.29a7.77,7.77,0,0,0,1.09-.08L90,18.67h2.52l-2-5.33A4.15,4.15,0,0,0,92.08,9.81Zm-4.81,2V8.07c1.45-.12,2.29.41,2.29,1.82S88.59,11.82,87.27,11.81Z"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      );
  }
}

LogoIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['dark', 'light']),
};
LogoIcon.defaultProps = {
  className: '',
  type: 'dark',
};

export default LogoIcon;
